import { styled } from "lib/theme";
import { LibraryTemplate } from "server/zsl/templates/types";
import { projectTemplateToTemplateSelectorProps } from "components/ModalCreateProject/data";
import {
  TemplateItem,
  TemplateItemProps,
} from "components/ProjectTemplates/TemplateItem";
import { Spacer } from "@zapier/design-system";

const Wrapper = styled.div`
  max-height: 500px;
`;

const TemplatesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
`;

export type TemplateItem = Pick<
  TemplateItemProps,
  "title" | "description" | "previewImage" | "icon" | "tablesCount"
> & { id: string };

export type Group = {
  title: string;
  templates: TemplateItem[];
};

export type TemplateSelectorProps = {
  templates: LibraryTemplate[];
  onSelect: (templateKey: string) => void;
  onUpgrade: (templateKey: string) => void;
  isPaidAccount: boolean;
  tablesCount: number;
  tablesLimit: number;
  zapierUpgradeUrl: string;
};

export function TemplateSelector(props: TemplateSelectorProps) {
  const formattedTemplates = props.templates.map(
    projectTemplateToTemplateSelectorProps
  );

  return (
    <Wrapper>
      <TemplatesGrid>
        {formattedTemplates.map((template) => {
          return (
            <Template
              key={template.id}
              template={template}
              onUpgrade={props.onUpgrade}
              onSelect={props.onSelect}
              isPaidAccount={props.isPaidAccount}
              zapierUpgradeUrl={props.zapierUpgradeUrl}
              tablesCount={props.tablesCount}
              tablesLimit={props.tablesLimit}
            />
          );
        })}
        <Spacer height={10} />
      </TemplatesGrid>
    </Wrapper>
  );
}

function Template(props: {
  template: TemplateItem;
  onUpgrade: (templateKey: string) => void;
  onSelect: (templateKey: string) => void;
  isPaidAccount: boolean;
  zapierUpgradeUrl: string;
  tablesCount: number;
  tablesLimit: number;
}) {
  const templateHasTables = props.template.tablesCount > 0;

  const templateExceedsTablesLimit =
    props.tablesCount + props.template.tablesCount > props.tablesLimit;

  const exceedsTablesLimit = templateHasTables && templateExceedsTablesLimit;

  const onClick = exceedsTablesLimit
    ? undefined
    : () => props.onSelect(props.template.id);

  return (
    <TemplateItem
      key={props.template.id}
      {...props.template}
      onClick={onClick}
      onUpgrade={props.onUpgrade}
      exceedsTablesLimit={exceedsTablesLimit}
      isPaidAccount={props.isPaidAccount}
      zapierUpgradeUrl={props.zapierUpgradeUrl}
    />
  );
}
