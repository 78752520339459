import { Button, Link } from "@zapier/design-system";
import { CategoryWithPreviewImage } from ".";
import { styled } from "lib/theme";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export function FooterActions(props: {
  selectedCategory?: CategoryWithPreviewImage["slug"];
  setSelectedCategory: (category?: CategoryWithPreviewImage["slug"]) => void;
  handleBlankClick: () => void;
  baseUrl: string;
}) {
  if (!props.selectedCategory) {
    return (
      <>
        <Button
          color="tertiary"
          onClick={props.handleBlankClick}
          iconBefore="formAdd"
        >
          Start from scratch
        </Button>
        <Link href={`${props.baseUrl}/templates`} target="_blank">
          Visit the Template Library to see more examples
        </Link>
      </>
    );
  }

  return (
    <Wrapper>
      <Button
        color="tertiary"
        size="small"
        onClick={() => props.setSelectedCategory(undefined)}
      >
        Back
      </Button>
      <Button
        color="tertiary"
        size="small"
        onClick={props.handleBlankClick}
        iconBefore="formAdd"
      >
        Start from scratch
      </Button>
    </Wrapper>
  );
}
