import { ColorsCSS as Colors, Text } from "@zapier/design-system";
import { CategoryWithPreviewImage } from ".";
import { styled } from "lib/theme";
import Image from "next/image";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const CategoriesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Category = styled.button`
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid ${Colors.neutral400};

  &:hover {
    border-color: ${Colors.blue600};
  }
`;

const CategoryDescription = styled.div`
  flex-grow: 1;
`;

export function CategorySelection(props: {
  categories: CategoryWithPreviewImage[];
  onSelect: (category: CategoryWithPreviewImage) => void;
}) {
  return (
    <Wrapper>
      <CategoriesWrapper>
        {props.categories.map((category) => (
          <Category
            key={category.slug}
            onClick={() => props.onSelect(category)}
          >
            <Image height={150} src={category.previewImage} alt="" />
            <Text type="paragraph1ShortSemibold">{category.name}</Text>
            <CategoryDescription>
              <Text type="paragraph3">{category.description}</Text>
            </CategoryDescription>
          </Category>
        ))}
      </CategoriesWrapper>
    </Wrapper>
  );
}
