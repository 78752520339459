import {
  ModalContent,
  ModalContentBody,
  Spacer,
  Spinner,
  Text,
} from "@zapier/design-system";
import { SpinnerWrapper } from "./index";

export function LoadingContent() {
  return (
    <ModalContent>
      <ModalContentBody align="center">
        <div>
          <Text type="paragraph1Bold">Building your Interface...</Text>
        </div>
        <Spacer height={15} />
        <div>
          <Text type="paragraph3">
            Hold tight! Your shiny new Interface will be ready soon.
          </Text>
        </div>
        <Spacer height={30} />
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </ModalContentBody>
    </ModalContent>
  );
}
