import {
  Modal,
  ModalContentBody,
  ModalContentFooter,
  ModalContentHeader,
  Spacer,
  Text,
} from "@zapier/design-system";
import { styled } from "lib/theme";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CustomModalContent } from "components/Modal/CustomModalContent";
import { Spinner } from "components/Spinner";
import { Category } from "lib/contentful/lib/client";
import { TablesProvider } from "lib/context/tables-context";
import { ClientUserProfile } from "lib/context/user-context";
import { pagePath, projectPath } from "lib/route-helpers";
import { useTrackingContext } from "observability";
import { toast } from "react-hot-toast";
import { config } from "@/config";
import { trpc } from "utils/trpc";
import {
  MixpanelEvents,
  useMixpanelContext,
} from "../../observability/mixpanel";
import { LoadingContent } from "./LoadingContent";

import customerPortalThumbnail from "assets/images/project-category-thumbnails/customer-portal.png";
import formsThumbnail from "assets/images/project-category-thumbnails/forms.png";

import { startCase } from "lodash";
import { StaticImageData } from "next/image";
import { LibraryTemplate } from "server/zsl/templates/types";
import { CategorySelection } from "./CategorySelection";
import { FooterActions } from "./FooterActions";
import { TemplateSelector } from "./TemplateSelector";
import { useSplitFlags } from "lib/context/split-context";

export type CategoryWithPreviewImage = Category & {
  description: string;
  previewImage: StaticImageData;
};

export const categories: CategoryWithPreviewImage[] = [
  {
    name: "Form",
    slug: "forms",
    description:
      "Forms can be used to capture information from your leads or customers. You can embed forms on your website or publish them directly.",
    previewImage: formsThumbnail,
  },
  {
    name: "Customer Portal",
    slug: "customer-portal",
    description:
      "Create web portals for your team or customers. Portals can either be public or require users to login. Embed tables, kanban boards, and more!",
    previewImage: customerPortalThumbnail,
  },
];

export const templateKeysByCategory: Record<
  CategoryWithPreviewImage["slug"],
  LibraryTemplate["key"][]
> = {
  forms: [
    "get-in-touch",
    "free-offer",
    "invoice-form",
    "employee-availability-form-tracker",
  ],
  "customer-portal": [
    "marketplace",
    "product-feedback",
    "request-portal",
    "simple-crm",
  ],
};

const ZAPIER_BASE_URL = config().NEXT_PUBLIC_ZAPIER_API_BASE_URL;

export const SpinnerWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

const DEFAULT_TABLES_LIMIT = config().DEFAULT_TABLES_LIMIT;

type ModalCreateProjectProps = {
  accountId: number;
  onClosed: () => void;
  templateKey?: string | null;
  user: ClientUserProfile;
};

export function ModalCreateProject(props: Readonly<ModalCreateProjectProps>) {
  const [showTemplatesAtTopLevelTreatmentOn] = useSplitFlags([
    "interfaces_enable-top-level-templates",
  ]);

  const ZAPIER_UPGRADE_URL = `${ZAPIER_BASE_URL}/app/settings/billing?upgradeProduct=tables-premium?utm_campaign=zpr-gbl-exp-evr-add_on_purchase_interfaces_product-prd&utm_medium=product&utm_source=zapier`;

  const [selectedCategory, setSelectedCategory] =
    useState<CategoryWithPreviewImage["slug"]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const passedTemplate = useRef(props.templateKey);

  const router = useRouter();

  const { emitBuilderInteractionEvent } = useTrackingContext();
  const { track: mixpanelTrack } = useMixpanelContext();

  const utils = trpc.useUtils();

  const { data: { templates = [] } = {} } = trpc.templates.list.useQuery();

  const { data: tables, isLoading: isLoadingTables } =
    trpc.tableTables.list.useQuery(undefined);
  const tablesCount = tables?.data.length ?? 0;

  const { data: limits, isLoading: isLoadingLimits } =
    trpc.tableTables.limits.useQuery(undefined);
  const tablesLimit = limits?.data.max_table_count ?? DEFAULT_TABLES_LIMIT;

  const planStatusAmount: number =
    props.user.entitlements.plan_status?.amount ?? 0;
  const isPaidAccount = planStatusAmount > 0;

  const mutation = trpc.projects.create.useMutation({
    onSuccess(response) {
      utils.projects.list.setData(undefined, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          projects: [...prev, response.project],
        };
      });
    },
    onError(err: any) {
      let toastMessage = "Interface creation failed.";

      const isTableLimit = err?.data?.errors?.includes(
        "Created table count did not match template input"
      );

      const isProjectLimit = err?.data?.code === "interfaces:limits:projects";

      if (isTableLimit) {
        toastMessage = `Upgrade your Tables limits to use this template.`;
      } else if (isProjectLimit) {
        toastMessage = `You have reached the limit of Interfaces for your plan. Please upgrade your plan to create more.`;
        setTimeout(() => {
          window.location.assign(
            `${config().NEXT_PUBLIC_INTERFACES_BASE_URL}/pricing`
          );
        }, 5000);
      }

      toast.error(toastMessage);
    },
  });

  const createProject = useCallback(
    async (templateKey: string) => {
      setIsSubmitting(true);

      try {
        // Create the project
        const { project } = await mutation.mutateAsync({
          templateKey: templateKey,
        });

        const pages = project.pages;

        // Track it
        mixpanelTrack(MixpanelEvents.CREATED_PROJECT, {
          reactComponent: "ModalCreateProject",
          template: templateKey,
          experimentName: "interfaces_enable-top-level-templates",
          experimentLane: showTemplatesAtTopLevelTreatmentOn
            ? "treatment"
            : "control",
        });

        emitBuilderInteractionEvent({
          interaction_raw_text: templateKey,
          interaction_goal: "create new project",
          interfaces_project_id: project?.id,
          event_action: "click",
          option_selected: showTemplatesAtTopLevelTreatmentOn
            ? "treatment-top_level_template"
            : "control-nested_template",
        });

        if (templateKey === "form" || templateKey === "lead-capture") {
          emitBuilderInteractionEvent({
            interaction_goal: "add form component",
            interfaces_project_id: project?.id,
            event_action: "click",
          });
        }

        // Redirect to the project
        if (project) {
          const pageIds = Object.keys(pages);

          let redirectPath = projectPath({ projectId: project.id });

          if (templateKey === "blank") {
            redirectPath = pagePath({
              pageId: pageIds[0],
            });
          }

          void router.push(redirectPath);
        }
      } catch (err: any) {
        console.error(err);

        setIsSubmitting(false);
        return;
      }
    },
    [
      emitBuilderInteractionEvent,
      mixpanelTrack,
      mutation,
      router,
      showTemplatesAtTopLevelTreatmentOn,
    ]
  );

  useEffect(() => {
    if (passedTemplate.current) {
      void createProject(passedTemplate.current);
      passedTemplate.current = null;
    }
  }, [createProject]);

  const handleBlankClick = useCallback(() => {
    mixpanelTrack("Clicked Start from Scratch", {
      reactComponent: "ModalContentHeader",
      experimentName: "interfaces_enable-top-level-templates",
      experimentLane: showTemplatesAtTopLevelTreatmentOn
        ? "treatment"
        : "control",
    });
    emitBuilderInteractionEvent({
      interaction_raw_text: `Clicked start from scratch button`,
      interaction_goal: `click start from scratch`,
      event_action: "click",
      option_selected: showTemplatesAtTopLevelTreatmentOn
        ? "treatment-top_level_start_from_scratch"
        : "control-nested_start_from_scratch",
    });
    void createProject("blank");
  }, [
    createProject,
    emitBuilderInteractionEvent,
    mixpanelTrack,
    showTemplatesAtTopLevelTreatmentOn,
  ]);

  const templatesForSelectedCategory = useMemo(() => {
    if (!selectedCategory) return [];
    return templates.filter((t) =>
      templateKeysByCategory[selectedCategory]?.includes(t.key)
    );
  }, [templates, selectedCategory]);

  const renderBody = () => {
    if (isSubmitting) return <LoadingContent />;

    return (
      <CustomModalContent
        width="800px"
        height={showTemplatesAtTopLevelTreatmentOn ? "1000px" : undefined}
      >
        <ModalContentHeader>
          <ModalContentHeaderLayout>
            <Text type="pageHeader7">
              {selectedCategory
                ? `Choose a ${startCase(
                    selectedCategory
                  ).toLowerCase()} template to start with`
                : "What do you want to build today?"}
            </Text>
          </ModalContentHeaderLayout>
        </ModalContentHeader>
        <ModalContentBody scrollable>
          {isLoadingTables || isLoadingLimits ? (
            <SpinnerWrapper>
              <SpinnerLayout>
                <Spinner />
              </SpinnerLayout>
            </SpinnerWrapper>
          ) : (
            <TablesProvider accountId={props.accountId}>
              {showTemplatesAtTopLevelTreatmentOn ? (
                <TemplateSelector
                  templates={templates.filter((t) =>
                    [
                      "contact-us-form",
                      "client-portal",
                      "sales-crm",
                      "expenses-form-hub",
                    ].includes(t.key)
                  )}
                  onSelect={(templateKey) => createProject(templateKey)}
                  onUpgrade={(templateKey) => {
                    emitBuilderInteractionEvent({
                      interaction_raw_text: `Upgrade Tables limits to create a ${templateKey} project`,
                      interaction_goal: "Upgrade Tables limits",
                      event_action: "click",
                    });
                  }}
                  isPaidAccount={isPaidAccount}
                  tablesCount={tablesCount}
                  tablesLimit={tablesLimit}
                  zapierUpgradeUrl={ZAPIER_UPGRADE_URL}
                />
              ) : null}
              {!selectedCategory && !showTemplatesAtTopLevelTreatmentOn && (
                <CategorySelection
                  categories={categories}
                  onSelect={(category: CategoryWithPreviewImage) => {
                    mixpanelTrack("Clicked Template Category", {
                      reactComponent: "ModalCreateProject",
                      category: category,
                      experimentName: "interfaces_enable-top-level-templates",
                      experimentLane: "control",
                    });
                    emitBuilderInteractionEvent({
                      interaction_raw_text: `Clicked ${category.name} category`,
                      interaction_goal: `Click onboarding template category`,
                      event_action: "click",
                    });
                    setSelectedCategory(category.slug);
                  }}
                />
              )}

              {selectedCategory && !showTemplatesAtTopLevelTreatmentOn ? (
                <TemplateSelector
                  templates={templatesForSelectedCategory}
                  onSelect={(templateKey) => createProject(templateKey)}
                  onUpgrade={(templateKey) => {
                    emitBuilderInteractionEvent({
                      interaction_raw_text: `Upgrade Tables limits to create a ${templateKey} project`,
                      interaction_goal: "Upgrade Tables limits",
                      event_action: "click",
                    });
                  }}
                  isPaidAccount={isPaidAccount}
                  tablesCount={tablesCount}
                  tablesLimit={tablesLimit}
                  zapierUpgradeUrl={ZAPIER_UPGRADE_URL}
                />
              ) : null}
            </TablesProvider>
          )}
        </ModalContentBody>
        <Spacer height={20} />
        <ModalContentFooter>
          <ModalFooterLayout>
            <FooterActions
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              handleBlankClick={handleBlankClick}
              baseUrl={ZAPIER_BASE_URL}
            />
            <Spacer height={20} />
          </ModalFooterLayout>
        </ModalContentFooter>
      </CustomModalContent>
    );
  };

  return (
    <Modal
      onClosed={props.onClosed}
      canClose={!isSubmitting}
      ariaLabel="Create project"
    >
      {renderBody()}
    </Modal>
  );
}

const SpinnerLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 560px;
`;

export const ModalContentHeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px -20px 30px 0;
`;

export const ModalFooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;
